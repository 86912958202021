import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IUser {
    id: string,
    username: string,
    first_name: string,
    last_name: string,
    language_code: string
}

interface IThemeParams {
    section_header_text_color?: string,
    destructive_text_color?: string,
    accent_text_color?: string,
    subtitle_text_color?: string,
    bg_color?: string,
    button_color?: string,
    secondary_bg_color?: string,
    header_bg_color?: string,
    button_text_color?: string,
    link_color?: string,
    text_color?: string,
    hint_color?: string,
    section_bg_color?: string
}

interface ITelegramState {
    user: IUser | null,
    refKey: string | null,
    tgWebAppVersion: string | null,
    tgWebAppPlatform: string | null,
    tgWebAppStartParam: string | null,
    tgWebAppThemeParams: IThemeParams | null,
    isTelegram: boolean
}

const initialState: ITelegramState = {
    user               : null,
    refKey             : null,
    tgWebAppVersion    : null,
    tgWebAppPlatform   : null,
    tgWebAppThemeParams: null,
    tgWebAppStartParam : null,
    isTelegram         : false
};

export const slice = createSlice({
    name    : 'telegram',
    initialState,
    reducers: {
        setTelegramData: (state, action: PayloadAction<Partial<ITelegramState>>) => {
            return { ...state, ...action.payload };
        },
        resetTelegramData: () => initialState
    }
});

export const { setTelegramData, resetTelegramData } = slice.actions;
export default slice.reducer;
