import React, { Suspense, useLayoutEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useAccount } from 'wagmi';
import { useTranslation } from 'react-i18next';

import { useClassnames } from '#hook/use-classnames';
import api from '#adapter/api/main';

import { Logo } from '#component/logo';
import { useAffilateLink } from '#component/referral/hooks/use-affilate-link';
import { pageParamMap } from '#component/user-statistics';
// import { SignInNotification } from '#component/global-notification/sign-in';

import { BottomNav } from './components/bottom-nav'; // Adjust the path if necessary
import { useSessionStarted } from './components/hooks/use-session-started';
import { useTelegramData } from './components/hooks/use-telegram-data';

import style from './index.module.pcss';

const TelegramLayout = () => {
    const cn = useClassnames(style);
    const { isConnected, address } = useAccount();
    const { t } = useTranslation('routes/telegram');
    const location = useLocation();
    const { pathname } = useLocation();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const trackPayload = api.endpoints.apiV1UsersTrack.useQuery({
        query: {
            page: pageParamMap[pathname] || 'main'
        }
    }, {
        pollingInterval: 60000
    });

    useAffilateLink();
    useTelegramData();
    useSessionStarted();

    // Scroll to top if path changes
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    return (
        <div>
            <header className={cn('header')}>
                <Logo
                    to="airdrops"
                    className={cn('header__logo')}
                />
                {isConnected && address
                && (
                    <div className={cn('header__right')}>

                        <span className={cn('header__wallet')}>{t('your_wallet')}</span>
                        <span>{`${address?.slice(0, 6)}...${address?.slice(-4)}`}</span>
                    </div>
                )}
            </header>
            {/* <SignInNotification /> */}
            <main>
                <Suspense fallback={null}>
                    <Outlet />
                </Suspense>
            </main>

            <footer>
                <BottomNav />
            </footer>
        </div>
    );
};

export default TelegramLayout;
