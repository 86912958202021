import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAccount } from 'wagmi';
import Cookies from 'js-cookie';
import { add } from 'date-fns';
import { Link, useLocation } from 'react-router-dom';

import { type TStyle, useClassnames } from '#hook/use-classnames';
import { useSelector } from '#core/store';
import { useAmplitude } from '#adapter/amplitude/use-amplitude';
import taskReward from '#abi/tasks-claim';
import { IRewardsResponse } from '#adapter/api/main/api/v1/tasks/rewards';
import api from '#adapter/api/main';
import { padStart } from '#helper/pad-start';

import { Button } from '#component/button';
import { IconPw3Token } from '#component/icons/pw3-token';
import { slice } from '#component/media-query/slice';
import { useHandleStatusChange } from '#route/claim/helpers/use-handle-status-change';
import { useHandleTaskClaim } from '#route/claim/helpers/use-handle-task-claim';
import { DISABLED_STATUSES } from '#route/claim/helpers/colomns';
import { CountdownDate } from '#component/countdown-date';

import style from './index.module.pcss';

export interface IProps {
    readonly className?: TStyle | string
}

const TIMER_DATE_COOKIE_NAME = 'cookie-claim-gift-timer-date';
const HIDE_COOKIE_NAME = 'cookie-claim-gift-hide';

export const ClaimGift = (props: IProps) => {
    const cn = useClassnames(style, props.className);
    const { track } = useAmplitude();
    const { pathname } = useLocation();
    const { connector, address } = useAccount();
    const { t } = useTranslation(undefined, { keyPrefix: 'components.claim-gift' });
    const isMobile = useSelector((store) => store[slice.name].isMobile);
    const [hidden, setHidden] = useState(Cookies.get(HIDE_COOKIE_NAME) === 'true');

    const { data: activeTaskRewards, isLoading, refetch } = api.useApiV1TasksRewardsGetQuery();
    const [foundPermit, setFoundPermit] = useState<IRewardsResponse['active_permits'][0] | null>(null);
    const { statusMap, handleStatusChange } = useHandleStatusChange();
    const handleTaskClaim = useHandleTaskClaim({ abi: taskReward });

    useEffect(() => {
        if(activeTaskRewards) {
            const permit = activeTaskRewards.active_permits.find(
                (p) => p?.status !== 'completed' && p?.kind === 'pw3_congrats'
            );

            setFoundPermit(permit || null);
        }
    }, [activeTaskRewards, isLoading, refetch]);

    const sendEvent = useCallback((name: string) => {
        track(name, {
            wallet_id  : connector?.id,
            wallet_name: connector?.name,
            path       : pathname
        });

        window.dataLayer?.push({
            event      : name,
            wallet_id  : connector?.id,
            wallet_name: connector?.name,
            path       : pathname
        });
    }, [connector, pathname]);

    useEffect(() => {
        if(foundPermit) {
            sendEvent('widget_pw3_promo_displayed');
        }
    }, [foundPermit, sendEvent]);

    const elTimer = useMemo(() => {
        if(hidden) {
            return null;
        }

        // Получаем дату до которой будет показываться таймер из куки
        const cookieTimerDate = Cookies.get(TIMER_DATE_COOKIE_NAME);
        let currentDate = cookieTimerDate ? new Date(cookieTimerDate) : null;

        // Если даты в куке нет, то устанавливаем таймер на 23:59:59 и сетим куку
        if(!currentDate) {
            const newDate = add(new Date(), { hours: 23, minutes: 59, seconds: 59 });

            Cookies.set(TIMER_DATE_COOKIE_NAME, newDate.toISOString());

            currentDate = newDate;
        }

        return (
            <CountdownDate
                date={currentDate}
                onEnd={() => {
                    // По тз, когда таймер заканчивается, мы должны прятать весь компонент claim-gift на 5 минут
                    // Ну и заодно удаляем куку таймера, чтобы после того как пройдет 5 минут, он показывался с новой датой
                    Cookies.remove(TIMER_DATE_COOKIE_NAME);

                    const cookieHideExpire = add(new Date(), { minutes: 5 });

                    Cookies.set(HIDE_COOKIE_NAME, 'true', { expires: cookieHideExpire });

                    // Стейт для реактивности
                    setHidden(true);
                }}
                children={(duration) => (
                    <div
                        className={cn('claim-gift__gift-timer')}
                    >
                        <div
                            className={cn('claim-gift__gift-timer-block')}
                        >
                            <strong>{padStart(duration.days)}</strong>
                            <span>D</span>
                        </div>
                        <span>:</span>
                        <div
                            className={cn('claim-gift__gift-timer-block')}
                        >
                            <strong>{padStart(duration.hours)}</strong>
                            <span>H</span>
                        </div>
                        <span>:</span>
                        <div
                            className={cn('claim-gift__gift-timer-block')}
                        >
                            <strong>{padStart(duration.minutes)}</strong>
                            <span>M</span>
                        </div>
                        <span>:</span>
                        <div
                            className={cn('claim-gift__gift-timer-block')}
                        >
                            <strong>{padStart(duration.seconds)}</strong>
                            <span>S</span>
                        </div>
                    </div>
                )}
            />
        );
    }, [hidden]);

    if(!foundPermit) {
        return null;
    }

    const disabled = DISABLED_STATUSES.has(statusMap.uniqueId) || !foundPermit;

    return (
        <div className={cn('claim-gift')}>
            <h2 className={cn('claim-gift__title')}>
                {t('title')}
            </h2>
            <p className={cn('claim-gift__text')}>
                {t('text')}
            </p>
            <div className={cn('claim-gift__gift')}>
                {!isMobile && <IconPw3Token svg={{ className: cn('claim-gift__gift-icon') }} />}
                <h3 className={cn('claim-gift__gift-title')}>
                    {t('gift.title')}
                </h3>
                <p className={cn('claim-gift__gift-text')}>
                    {t('gift.text')}
                </p>
                <Button
                    className={cn('claim-gift__gift-button')}
                    presetStyle="primary-orange"
                    disabled={disabled}
                    uppercase={true}
                    onClick={() => {
                        sendEvent(address ? 'widget_pw3_promo_claim_clicked' : 'widget_pw3_promo_claim_visitor_clicked');

                        void handleTaskClaim(
                            { id: foundPermit?.id, trackClaim: 'claim_pw3_promo_success' },
                            (status, customMessage) => handleStatusChange(`uId-${foundPermit?.id}`, status, customMessage)
                        );
                    }}
                >
                    {t('gift.button')}
                </Button>
                <Link
                    className={cn('claim-gift__gift-learn-more')}
                    to="/pw3-token"
                >
                    {t('gift.learn-more')}
                </Link>
                {elTimer}
            </div>
        </div>
    );
};
