import React, { useMemo, useState } from 'react';
import { useAccount } from 'wagmi';
import { numberToHex, EIP1193Provider } from 'viem';
import { useTranslation } from 'react-i18next';

import { CHAIN } from '#core/wagmi';
import { useClassnames } from '#hook/use-classnames';

import { Button } from '#component/button';

import styles from './index.module.pcss';

export const SwitchChain = () => {
    const { connector } = useAccount();
    const [error, setError] = useState<string | null>(null);
    const cn = useClassnames(styles);
    const { t } = useTranslation();
    const elError = useMemo(() => {
        if(error) {
            return <div className={cn('switch-chain__error')}>{error}</div>;
        }

        return null;
    }, [error]);

    return (
        <div className={cn('switch-chain')}>
            <h2 className={cn('switch-chain__title')}>
                {t('components.wagmi.switch-chain.title')}
            </h2>
            <div className={cn('switch-chain__content')}>
                <p className={cn('switch-chain__text')}>
                    {t('components.wagmi.switch-chain.text')}
                </p>
                <Button
                    className={cn('switch-chain__button')}
                    onClick={async (e) => {
                        e.preventDefault();

                        if(connector) {
                            try {
                                const provider = await connector.getProvider() as EIP1193Provider;

                                if(provider.request) {
                                    await provider.request({
                                        method: 'wallet_addEthereumChain',
                                        params: [{
                                            chainId          : numberToHex(CHAIN.id),
                                            chainName        : CHAIN.name,
                                            nativeCurrency   : CHAIN.nativeCurrency,
                                            rpcUrls          : CHAIN.rpcUrls.default.http,
                                            blockExplorerUrls: [CHAIN.blockExplorers.default.url]
                                        }]
                                    });

                                    await provider.request({ method: 'eth_chainId' });

                                    connector.onChainChanged(numberToHex(CHAIN.id));
                                }
                            } catch(error) {
                                setError((error as Error).message);
                            }
                        }
                    }}
                >
                    {t('components.wagmi.switch-chain.button')}
                </Button>
            </div>
            {elError}
        </div>
    );
};
