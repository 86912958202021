import React, { createContext, ReactNode, useContext, useMemo } from 'react';
import { useAccount, useReadContracts } from 'wagmi';

import abiGameToken from '#abi/game-token';
import type { TCode200 as IRevenueStreams } from '#adapter/api/main/api/v1/revenue_streams/get/index';
import api from '#adapter/api/main';

// Типизация контекста (если используется TypeScript)
interface ITokenDecimalsContextType {
    tokenDecimalsData: TTokenDecimalsData,
    isLoadingDecimals: boolean,
    decimalsMap: Map<string, number>
}

interface IContractReadResult {
    error?: Error,
    result?: unknown,
    status: 'failure' | 'success'
}

type TTokenDecimalsData = Array<IContractReadResult> | undefined;

// Создаем контекст с начальными значениями
const tokenDecimalsContext = createContext<ITokenDecimalsContextType | undefined>(undefined);

export type TRevenueStreamMap = Record<number, {
    stream_id: number,
    name?: string | null,
    token_address?: string | null,
    kind?: string | null
}>;

export const mapRevenuesByStreamId = (data: IRevenueStreams): TRevenueStreamMap => {
    return data?.reduce((acc: TRevenueStreamMap, item) => {
        const { stream_id, name, token_address, kind } = item;

        if(stream_id !== null && stream_id !== undefined) {
            acc[stream_id] = { stream_id, name, token_address, kind };
        }

        return acc;
    }, {});
};

interface ITokenDecimalsProviderProps {
    children: ReactNode
}

// Получаем данные о decimals для токенов
export const fetchTokenDecimals = (mappedRevenues: TRevenueStreamMap, isConnected: boolean) => {
    if(!isConnected) { return []; }

    // Используем Map для фильтрации уникальных контрактов по token_address
    const uniqueContracts = new Map<string, { address: `0x${string}`, abi: any, functionName: string }>();

    Object.values(mappedRevenues).forEach((revenue) => {
        const tokenAddress = revenue.token_address;

        if(tokenAddress && !uniqueContracts.has(tokenAddress)) {
            uniqueContracts.set(tokenAddress, {
                address     : tokenAddress as `0x${string}`,
                abi         : abiGameToken,
                functionName: 'decimals'
            });
        }
    });

    // Возвращаем массив уникальных контрактов
    return Array.from(uniqueContracts.values());
};

// Получаем decimalsMap
export const buildDecimalsMap = (tokenContractsToRead: Array<{ address: `0x${string}` }>, tokenDecimalsData: any, isLoadingTokenDecimals: boolean) => {
    const decimalsMap = new Map<string, number>();

    if(isLoadingTokenDecimals) {
        return decimalsMap;
    }

    tokenContractsToRead.forEach((contract, index) => {
        const { result, status } = tokenDecimalsData?.[index] || {};

        if(status === 'success' && result !== undefined) {
            decimalsMap.set(contract.address, result as number);
        } else {
            console.warn(`Failed to fetch decimals for address: ${contract.address}`);
        }
    });

    return decimalsMap;
};

// Провайдер для контекста
export const TokenDecimalsProvider = ({ children }: ITokenDecimalsProviderProps) => {
    const { isConnected } = useAccount();
    const { mappedRevenues, isLoadingRevenues } = api.endpoints.apiV1RevenueStreamsGet.useQuery({
        query: { limit: 1000 }
    }, {
        selectFromResult: ({ data, isLoading, error }) => ({
            mappedRevenues   : data ? mapRevenuesByStreamId(data) : {},
            isLoadingRevenues: isLoading,
            errorRevenues    : error
        })
    });

    // Always call useMemo, perform conditional check inside the memoization function
    const tokenContractsToRead = useMemo(() => {
        return (!mappedRevenues || typeof isConnected === 'undefined')
            ? []
            : fetchTokenDecimals(mappedRevenues, isConnected);
    }, [isConnected, mappedRevenues]);

    const { data: tokenDecimalsData, isLoading: isLoadingTokenDecimals } = useReadContracts({
        contracts: tokenContractsToRead
    });

    const decimalsMap = useMemo(() => {
        return tokenContractsToRead.length === 0
            ? new Map()
            : buildDecimalsMap(tokenContractsToRead, tokenDecimalsData, isLoadingTokenDecimals);
    }, [tokenContractsToRead, tokenDecimalsData, isLoadingTokenDecimals]);

    const isLoadingDecimals = useMemo(() => isLoadingTokenDecimals || isLoadingRevenues, [isLoadingTokenDecimals, isLoadingRevenues]);

    // Create the provider value regardless of the condition
    const providerValue = useMemo(() => {
        if(!mappedRevenues || typeof isConnected === 'undefined') {
            console.error('mappedRevenues or isConnected is not provided to TokenDecimalsProvider');

            // Provide a default fallback value here
            return {
                tokenDecimalsData: [],
                isLoadingDecimals: true,
                decimalsMap      : new Map()
            };
        }

        return {
            tokenDecimalsData,
            isLoadingDecimals,
            decimalsMap
        };
    }, [tokenDecimalsData, isLoadingDecimals, decimalsMap, mappedRevenues, isConnected]);

    return (
        <tokenDecimalsContext.Provider value={providerValue}>
            {children}
        </tokenDecimalsContext.Provider>
    );
};
// Экспортируем хук для использования контекста
export const useTokenDecimals = () => {
    const context = useContext(tokenDecimalsContext);

    if(context === undefined) {
        throw new Error('useTokenDecimals must be used within a TokenDecimalsProvider');
    }

    return context;
};
