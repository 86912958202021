import { useAccount } from 'wagmi';

import api from '#adapter/api/main';
import { CHAIN } from '#core/wagmi';

export const useWagmiFlow = () => {
    const { isConnected, address, chainId } = useAccount();
    const { data, isLoading } = api.endpoints.apiV1UsersWhoamiGet.useQuery();

    const hasNeedConnectWallet = !isConnected || !address;
    const hasNeedSwitchChain = chainId !== CHAIN.id;
    const hasNeedSign = data?.wallet !== address;

    return {
        isLoading,
        hasNeedConnectWallet,
        hasNeedSwitchChain,
        hasNeedSign,
        hasNeedFlow: hasNeedConnectWallet || hasNeedSwitchChain || hasNeedSign
    };
};
