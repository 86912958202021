import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDisconnect, useAccount, useSignMessage } from 'wagmi';
import { numberToHex, EIP1193Provider } from 'viem';

import { CHAIN } from '#core/wagmi';
import { useClassnames } from '#hook/use-classnames';
import api from '#adapter/api/main';
import { useAmplitude } from '#adapter/amplitude/use-amplitude';
import { useWagmiFlow } from '#hook/use-wagmi-flow';

import { GradientText } from '#component/gradient-text';
import { Button } from '#component/button';

import style from './index.module.pcss';

export const Component = () => {
    const cn = useClassnames(style);
    const { t } = useTranslation();
    const { signMessageAsync, isPending } = useSignMessage();
    const { hasNeedSwitchChain } = useWagmiFlow();
    const { connector, address } = useAccount();
    const { track } = useAmplitude();
    const { disconnect } = useDisconnect();
    const { isLoading: isLoadingUser } = api.endpoints.apiV1UsersWhoamiGet.useQuery();
    const [nonceRequest] = api.endpoints.apiV1AuthNoncePost.useMutation();
    const [authenticateRequest] = api.endpoints.apiV1UsersAuthenticatePost.useMutation();
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    const sendEvent = useCallback((name: string, additionalData: Record<string, unknown> = {}) => {
        track(name, {
            wallet_id  : connector?.id,
            wallet_name: connector?.name,
            path       : window.location.pathname,
            ...additionalData
        });

        window.dataLayer?.push({
            event      : name,
            wallet_id  : connector?.id,
            wallet_name: connector?.name,
            path       : window.location.pathname,
            ...additionalData
        });
    }, [connector]);

    useEffect(() => {
        window.dataLayer?.push({
            event: 'page_sign_in_displayed'
        });
    }, []);

    useEffect(() => {
        sendEvent('banner_welcome_displayed');
    }, [connector]);

    const handleSwitchChainClick = useCallback(async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        if(connector) {
            try {
                const provider = await connector.getProvider() as EIP1193Provider;

                if(provider.request) {
                    await provider.request({
                        method: 'wallet_addEthereumChain',
                        params: [{
                            chainId          : numberToHex(CHAIN.id),
                            chainName        : CHAIN.name,
                            nativeCurrency   : CHAIN.nativeCurrency,
                            rpcUrls          : CHAIN.rpcUrls.default.http,
                            blockExplorerUrls: [CHAIN.blockExplorers.default.url]
                        }]
                    });

                    await provider.request({ method: 'eth_chainId' });

                    connector.onChainChanged(numberToHex(CHAIN.id));
                }
            } catch(errorPayload) {
                sendEvent('wallet_sign_transaction_error', {
                    error: (errorPayload as Error).message
                });

                setError((errorPayload as Error).message);
            }
        }
    }, [connector]);

    const handleSignInClick = useCallback(async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        if(!isLoading) {
            sendEvent('btn_sign_message_clicked');
            setIsLoading(true);
            setError(null);

            try {
                const { nonce } = await nonceRequest({
                    body: {
                        user_address: address as `0x${string}`
                    }
                }).unwrap();

                const signature = await signMessageAsync({
                    message: `Your sign for Playweb3.io is here!\nApprove & enjoy\n\nAuthenticate: ${nonce}`,
                    connector
                });

                void authenticateRequest({
                    body: {
                        address: address as `0x${string}`,
                        nonce  : nonce as string,
                        signature
                    }
                })
                    .unwrap()
                    .then(() => {
                        sendEvent('new_user_registered');
                        api.util.resetApiState();
                    });
            } catch(errorPayload) {
                setError((errorPayload as Error).message);
            }

            setIsLoading(false);
        }
    }, [sendEvent]);

    if(isLoadingUser) {
        return null;
    }

    return (
        <div className={cn('sign-in')}>
            <GradientText
                children={t('routes.sign-in.title')}
                className={cn('sign-in__title')}
            />
            <div className={cn('sign-in__content')}>
                <p className={cn('sign-in__content-title')}>{t('routes.sign-in.subtitle')}</p>
                <p className={cn('sign-in__content-text')}>{t('routes.sign-in.text')}</p>
            </div>
            {error && <p className={cn('sign-in__error')}>{error}</p>}
            <div className={cn('sign-in__buttons')}>
                <Button
                    children={t('routes.sign-in.buttons.sign-in')}
                    className={cn('sign-in__button-sign-in')}
                    disabled={isLoading || isPending}
                    uppercase={true}
                    onClick={hasNeedSwitchChain ? handleSwitchChainClick : handleSignInClick}
                />
                <button
                    children={t('routes.sign-in.buttons.logout')}
                    className={cn('sign-in__button-logout', {
                        'sign-in__button-logout_disabled': isLoading || isPending
                    })}
                    disabled={isLoading || isPending}
                    onClick={(e) => {
                        e.preventDefault();
                        sendEvent('btn_welcome_logout_clicked');

                        void disconnect({ connector });
                    }}
                />
            </div>
        </div>
    );
};
