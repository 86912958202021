export const getDataTWA = (value: string = window.location.hash) => {
    if(value) {
        let source = value;

        if(source.startsWith('#')) {
            source = source.substring(1);
        }

        const searchParams = new URLSearchParams(source);

        const version = searchParams.get('tgWebAppVersion');
        const platform = searchParams.get('tgWebAppPlatform');
        let theme = searchParams.get('tgWebAppThemeParams');
        let data: any = searchParams.get('tgWebAppData');

        if(theme) {
            theme = JSON.parse(theme);
        }

        if(data) {
            const searchParamsData = new URLSearchParams(data as string);

            data = Object.fromEntries(searchParamsData);

            if(data?.user) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                data.user = JSON.parse(data.user);
            }
        }

        const start_param = typeof data === 'string' ? data.match(/start_param=([^&]*)/)?.[1] || null : null;

        // Определяем `isTelegram` на основе присутствия некоторых ключей
        const isTelegram = Boolean(data || version || platform);

        return {
            version,
            platform,
            data,
            theme,
            isTelegram,
            start_param
        };
    }

    return undefined;
};
