import React from 'react';
import { Spin } from 'antd';

import { useWagmiFlow } from '#hook/use-wagmi-flow';
import { useClassnames } from '#hook/use-classnames';

import { Connectors } from '../connectors';
import { SwitchChain } from '../switch-chain';
import { Sign } from '../sign';

import styles from './index.module.pcss';

export const FlowSteps = () => {
    const { isLoading, hasNeedConnectWallet, hasNeedSwitchChain, hasNeedSign } = useWagmiFlow();
    const cn = useClassnames(styles);

    if(isLoading) {
        return (
            <div className={cn('flow-steps__loading')}>
                <Spin
                    size="large"
                />
            </div>
        );
    }

    if(hasNeedConnectWallet) {
        return <Connectors />;
    }

    if(hasNeedSwitchChain) {
        return <SwitchChain />;
    }

    if(hasNeedSign) {
        return <Sign />;
    }

    return null;
};
