import { useEffect, useMemo, useState } from 'react';
import { useAccount } from 'wagmi';
import { Address } from 'viem';
import { useLocation } from 'react-router-dom';

import { api } from '#adapter/api/main';
import type { TCode200 as IRevenueStreams } from '#adapter/api/main/api/v1/revenue_streams/get/index';

import { TCombinedDataResult, getCombinedData } from './combined-data';
import { useTokenDecimals } from './decimals';
import { IUseTargetFromPath } from './use-target-path';

export enum EWallets {
    ClaimDevsWallet = 'claim_devs_wallet',
    ClaimHoldersWallet = 'claim_holders_wallet',
    ClaimP3DWallet = 'claim_p3d_wallet',
    VestingContract = 'vesting_contract',
    AffiliatesWallet = 'claim_p3d_affilate_wallet'
}

// Определение enum ETargets с PascalCase
export enum ETargets {
    Beneficiary = 0,
    Group = 1,
    PlatformTokenHolders = 2,
    Holders = 3
}

export type TTarget = ETargets;
// Используем enum как тип напрямую
export type TType = EWallets;

export type TRevenueStreamMap = Record<number, {
    stream_id: number,
    name?: string | null,
    token_address?: string | null,
    kind?: string | null
}>;

export const FAKE_INVESTOR = 'showAddress';

export const mapRevenuesByStreamId = (data: IRevenueStreams): TRevenueStreamMap => {
    return data?.reduce((acc: TRevenueStreamMap, item) => {
        const { stream_id, name, token_address, kind } = item;

        if(stream_id !== null && stream_id !== undefined) {
            acc[stream_id] = { stream_id, name, token_address, kind };
        }

        return acc;
    }, {});
};

export const useContractData = ({ type, target }: { type: TType, target?: IUseTargetFromPath }) => {
    const { address } = useAccount();
    // TODO error handling
    const { data: games, isLoading: isLoadingGames } = api.endpoints.apiV1GamesGet.useQuery(
        { query: { limit: 150 } },
        {
            refetchOnFocus    : true,
            refetchOnReconnect: true
        }
    );

    const { decimalsMap, isLoadingDecimals, tokenDecimalsData } = useTokenDecimals();

    // State for combined data
    const [combinedData, setCombinedData] = useState<TCombinedDataResult>({ igo: [], game: [], taxes: [] });
    const [isLoadingCombinedData, setIsLoadingCombinedData] = useState(true);

    const isLoadingPreparedData = isLoadingGames || isLoadingDecimals;

    // fake investor door
    const { search } = useLocation();
    const searchParams = useMemo(() => new URLSearchParams(search), [search]);
    const showAddress = searchParams.get(FAKE_INVESTOR);

    // Fetch combined data
    useEffect(() => {
        const fetchData = async () => {
            setIsLoadingCombinedData(true);
            try {
                const data = await getCombinedData({
                    games,
                    type,
                    address    : address as Address,
                    decimalsMap,
                    target     : target?.target,
                    // fake investor wallet
                    showAddress: showAddress as Address
                });

                setCombinedData(data);
            } catch(error) {
                console.error('Error fetching combined data:', error);
            } finally {
                setIsLoadingCombinedData(false);
            }
        };

        // Fetch combined data when all loading is done
        if(!isLoadingPreparedData && games && tokenDecimalsData) {
            void fetchData();
        }
    }, [games, isLoadingPreparedData, type, address, tokenDecimalsData, target]);

    return {
        isContractDataLoading: isLoadingPreparedData || isLoadingCombinedData,
        combinedData
    };
};
