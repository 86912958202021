import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { type TStyle, useClassnames } from '#hook/use-classnames';
import * as apiV1UsersTrackGet from '#adapter/api/main/api/v1/users/track/get';
import { numeral } from '#adapter/numeral';
import api from '#adapter/api/main';

import style from './index.module.pcss';

interface IProps {
    readonly className?: TStyle | string
}

const eligiblePaths = ['/', '/trade', '/launchpad', '/games', '/playweb3-stats'];

export const pageParamMap: Record<string, apiV1UsersTrackGet.IQueryParameters['page']> = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '/'              : 'main',
    '/trade'         : 'trade',
    '/launchpad'     : 'launchpad',
    '/games'         : 'games',
    '/playweb3-stats': 'playweb3_stats'
};

export const UserStatistics = (props: IProps) => {
    const cn = useClassnames(style, props.className);
    const { t } = useTranslation();
    const { data } = api.endpoints.apiV1StatisticsUserGet.useQuery();
    const { pathname } = useLocation();
    const eligiblePath = eligiblePaths.includes(pathname);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const trackPayload = api.endpoints.apiV1UsersTrack.useQuery({
        query: {
            page: pageParamMap[pathname] || 'main'
        }
    }, {
        pollingInterval: 60000
    });

    if(!data || !eligiblePath) {
        return null;
    }

    return (
        <div className={cn('user-statistics')}>
            <div className={cn('user-statistics__item')}>
                {t('components.user-statistics.registered')}
                {' '}
                <strong>{numeral(data?.total_users).format('0,0')}</strong>
            </div>
            <div className={cn('user-statistics__item')}>
                {t('components.user-statistics.online')}
                {' '}
                <strong>{numeral(data?.total_online_for_period).format('0,0')}</strong>
            </div>
        </div>
    );
};
