import React, { useMemo, useState } from 'react';
import { useConnectors, useConnect } from 'wagmi';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import { useClassnames } from '#hook/use-classnames';

import iconMetaMask from './icons/metamask.webp';
import iconWalletConnect from './icons/walletconnect.webp';
import iconCoinbase from './icons/coinbase.webp';

import styles from './index.module.pcss';

const icons = {
    metaMaskSDK      : iconMetaMask,
    walletConnect    : iconWalletConnect,
    coinbaseWalletSDK: iconCoinbase
};

export const Connectors = () => {
    const connectors = useConnectors();
    const { connect } = useConnect();
    const cn = useClassnames(styles);
    const { t } = useTranslation();
    const [error, setError] = useState<string | null>(null);
    const [activeConnector, setActiveConnector] = useState<string | null>(null);

    const elError = useMemo(() => {
        if(error) {
            return <p className={cn('connectors__error')}>{error}</p>;
        }
    }, [error]);

    return (
        <div
            className={cn('connectors')}
        >
            <h2 className={cn('connectors__title')}>
                {t('components.wagmi.connectors.title')}
            </h2>
            <ul
                className={cn('connectors__list')}
            >
                {connectors.filter((connector) => connector.id !== 'injected').map((connector) => (
                    <li
                        className={cn('connectors__connector', {
                            'connectors__connector_disabled': !!activeConnector
                        })}
                        key={connector.uid}
                        onClick={(e) => {
                            e.preventDefault();

                            if(!activeConnector) {
                                setActiveConnector(connector.uid);

                                window.dataLayer?.push({
                                    event      : 'wallet_connect_started',
                                    wallet_id  : connector.id,
                                    wallet_name: connector.name,
                                    path       : window.location.pathname
                                });

                                connect({ connector }, {
                                    onError: (connectError) => {
                                        setError(connectError.message);
                                    },
                                    onSettled: () => {
                                        setActiveConnector(null);

                                        window.dataLayer?.push({
                                            event      : 'wallet_selected',
                                            wallet_id  : connector.id,
                                            wallet_name: connector.name,
                                            path       : window.location.pathname
                                        });
                                    }
                                });
                            }
                        }}
                    >
                        <img
                            className={cn('connectors__connector-icon')}
                            src={connector.icon || icons[connector.id as keyof typeof icons]}
                            alt={connector.name}
                        />
                        <span className={cn('connectors__connector-name')}>
                            {connector.name}
                        </span>
                        {activeConnector === connector.uid && (
                            <div className={cn('connectors__connector-pending')}>
                                <Spin size="small" />
                            </div>
                        )}
                    </li>
                ))}
            </ul>
            {elError}
        </div>
    );
};
