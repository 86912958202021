import React, { ReactNode } from 'react';
import { WagmiProvider as WagmiProviderSource } from 'wagmi';
import { watchAccount } from 'wagmi/actions';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { config } from '#core/wagmi';
import api from '#adapter/api/main';
import { store } from '#core/store';

import { FlowModal } from '#component/wagmi/flow-modal';

export { ready } from '#core/wagmi';

const queryClient = new QueryClient();

export interface IProps {
    children?: ReactNode
}

watchAccount(config, {
    onChange(account) {
        if(account.isDisconnected) {
            void store.dispatch(
                api.endpoints.apiV1UsersLogoutPost.initiate()
            )
                .unwrap()
                .then(() => {
                    api.util.resetApiState();
                });
        }
    }
});

export const WagmiProvider = (props: IProps) => {
    return (
        <WagmiProviderSource config={config}>
            <QueryClientProvider client={queryClient}>
                {props.children}
                <FlowModal />
            </QueryClientProvider>
        </WagmiProviderSource>
    );
};
