import React from 'react';

import { IPropsSVG, SVG } from '.';

export interface IProps {
    readonly svg?: IPropsSVG
}

export const IconInfoWithCircle = (props: IProps) => {
    return (
        <SVG
            width="14"
            height="14"
            viewBox="0 0 14 14"
            {...props.svg}
        >
            <path
                d="M11.9528 2.05329C9.22079 -0.68205 4.78867 -0.68477 2.0533 2.04718C-0.682042 4.7792 -0.684795 9.21135 2.04722 11.9467C4.77917 14.6821 9.21135 14.6848 11.9467 11.9528C14.682 9.22085 14.6848 4.78863 11.9528 2.05329ZM7.97738 11.4418C7.97738 11.5498 7.88985 11.6373 7.78191 11.6373H6.21812C6.11018 11.6373 6.02265 11.5498 6.02265 11.4418V5.6367C6.02265 5.52869 6.11018 5.44123 6.21812 5.44123H7.78191C7.88985 5.44123 7.97738 5.52866 7.97738 5.6367V11.4418ZM7.00001 4.62999C6.37492 4.62999 5.86633 4.12147 5.86633 3.49634C5.86633 2.87127 6.37489 2.36265 7.00001 2.36265C7.62514 2.36265 8.1337 2.87124 8.1337 3.49634C8.1337 4.12147 7.62508 4.62999 7.00001 4.62999Z"
                fill="#8B8B8B"
            />
        </SVG>
    );
};

export default IconInfoWithCircle;
