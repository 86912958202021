import React from 'react';

import { IPropsSVG, SVG } from '.';

export interface IProps {
    readonly svg?: IPropsSVG,
    readonly width?: number | string,
    readonly height?: number | string
}

export const IconCoin = (props: IProps) => {
    const { width = 30, height = 31, svg } = props;

    return (
        <SVG
            width={width}
            height={height}
            viewBox="0 0 20 21"
            {...svg}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 0.5C15.5192 0.5 20 4.9809 20 10.5C20 16.0192 15.5192 20.5 10 20.5C4.48085 20.5 0 16.0192 0 10.5C0 4.9809 4.48085 0.5 10 0.5Z"
                fill="#FFDF26"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.0013 2.04523C14.6674 2.04523 18.4557 5.83354 18.4557 10.4996C18.4557 15.1657 14.6674 18.9541 10.0013 18.9541C5.3352 18.9541 1.54688 15.1657 1.54688 10.4996C1.54688 5.83354 5.3352 2.04523 10.0013 2.04523Z"
                fill="#EBB718"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.5167 16.9326C2.69968 15.3814 1.54688 13.0744 1.54688 10.5005C1.54688 5.83437 5.3352 2.04607 10.0013 2.04607C12.2292 2.04607 14.2571 2.9097 15.768 4.32029C14.2912 3.05947 12.3755 2.29798 10.2835 2.29798C5.61737 2.29798 1.82905 6.08628 1.82905 10.7524C1.82905 13.1905 2.86331 15.389 4.5167 16.9326Z"
                fill="#F09C04"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.84375 2.04666C9.89655 2.04568 9.94943 2.04523 10.0024 2.04523C14.6685 2.04523 18.4569 5.83354 18.4569 10.4996C18.4569 15.1657 14.6685 18.9541 10.0024 18.9541C9.94943 18.9541 9.89655 18.9536 9.84375 18.9526C14.4367 18.8679 18.1395 15.1128 18.1395 10.4996C18.1395 5.88656 14.4367 2.13143 9.84375 2.04666Z"
                fill="#FFF459"
            />
            <path
                d="M10.2385 6.39081C10.3123 6.38774 10.3875 6.38609 10.4642 6.38587V5.60513H11.361V6.43177C12.0289 6.50838 12.47 6.67192 12.8349 6.85539V8.07368C12.2637 7.84528 11.52 7.6422 10.5107 7.6422C9.55454 7.6422 9.02329 8.02291 9.02329 8.7209C9.02329 9.40616 9.28892 9.69806 10.192 9.93918L11.042 10.193C12.4763 10.5863 13.3395 11.1575 13.3395 12.7057C13.3395 13.9968 12.6969 14.907 11.361 15.2418V16.1641H10.4642V15.3727C10.3905 15.377 10.3152 15.38 10.2385 15.3817V16.1641H9.34179V15.3503C8.50807 15.274 7.85063 15.0727 7.36328 14.825V13.594C8.09368 13.8985 8.95689 14.127 9.95291 14.127C10.155 14.127 10.3377 14.1138 10.5021 14.0883C10.4895 14.0891 10.477 14.0899 10.4642 14.0906L10.9855 13.9572C11.4739 13.752 11.6927 13.3692 11.6927 12.8579C11.6927 12.1346 11.3607 11.8554 10.3647 11.5636L9.50137 11.3351C8.21318 10.9924 7.42969 10.3326 7.42969 8.86048C7.42969 7.69312 8.05603 6.82102 9.34179 6.51003V5.60513H10.2385V6.39081ZM9.34179 14.0291L10.2385 14.0965C9.92526 14.0962 9.62639 14.072 9.34179 14.0291Z"
                fill="#EE9E02"
            />
            <path
                d="M9.72682 6.00702C9.80058 6.00403 9.87581 6.00238 9.95258 6.00208V5.22134H10.8493V6.04798C11.5172 6.12459 11.9584 6.28814 12.3232 6.4716V7.68989C11.7521 7.46149 11.0084 7.25841 9.99906 7.25841C9.0429 7.25841 8.51165 7.63912 8.51165 8.33711C8.51165 9.02237 8.77727 9.31427 9.68034 9.5554L10.5303 9.80918C11.9646 10.2026 12.8278 10.7737 12.8278 12.3219C12.8278 13.613 12.1853 14.5232 10.8493 14.8579V15.7803H9.95258V14.9889C9.87882 14.9932 9.80352 14.9962 9.72682 14.9979V15.7803H8.83015V14.9666C7.99635 14.8902 7.33898 14.6889 6.85156 14.4412V13.2102C7.58203 13.5148 8.44524 13.7432 9.44134 13.7432C9.64328 13.7432 9.82609 13.73 9.99046 13.7045C9.97795 13.7053 9.96531 13.7061 9.95258 13.7068L10.4738 13.5734C10.9623 13.3681 11.1811 12.9855 11.1811 12.4741C11.1811 11.7508 10.849 11.4717 9.85301 11.1797L8.9898 10.9513C7.70153 10.6087 6.91797 9.94876 6.91797 8.47669C6.91797 7.30933 7.54431 6.43723 8.83015 6.12624V5.22134H9.72682V6.00702ZM8.83015 13.6454L9.72682 13.7127C9.41361 13.7124 9.11467 13.6881 8.83015 13.6454Z"
                fill="#F9ED43"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.1012 10.2947C18.1012 10.9156 18.5856 11.4188 19.1829 11.4188C18.5856 11.4188 18.1012 11.9222 18.1012 12.543C18.1012 11.9222 17.617 11.4188 17.0195 11.4188C17.617 11.4188 18.1012 10.9156 18.1012 10.2947Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.6553 13.4769C11.6553 14.1442 12.0816 14.6852 12.6075 14.6852C12.0816 14.6852 11.6553 15.2262 11.6553 15.8936C11.6553 15.2262 11.229 14.6852 10.7031 14.6852C11.229 14.6852 11.6553 14.1442 11.6553 13.4769Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.37147 3.61236C3.37147 4.27463 3.77211 4.81155 4.2663 4.81155C3.77211 4.81155 3.37147 5.34847 3.37147 6.01074C3.37147 5.34847 2.97082 4.81155 2.47656 4.81155C2.97082 4.81155 3.37147 4.27463 3.37147 3.61236Z"
                fill="white"
            />
        </SVG>
    );
};

export default IconCoin;
