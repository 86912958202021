import React, { useEffect } from 'react';

import { useSelector, useDispatch } from '#core/store';
import { useClassnames } from '#hook/use-classnames';
import { useWagmiFlow } from '#hook/use-wagmi-flow';

import Modal from '#component/modal';

import { FlowSteps } from '../flow-steps';
import { slice } from './slice';

import styles from './index.module.pcss';

export const FlowModal = () => {
    const isOpen = useSelector((store) => store[slice.name].isOpen);
    const dispatch = useDispatch();
    const cn = useClassnames(styles);
    const { hasNeedFlow, hasNeedSign, hasNeedConnectWallet } = useWagmiFlow();

    const closed = () => {
        dispatch(
            slice.actions.set({
                isOpen: false
            })
        );
    };

    useEffect(() => {
        if(!hasNeedConnectWallet) {
            closed();
        }
    }, [hasNeedFlow, hasNeedSign, hasNeedConnectWallet]);

    if(!isOpen) {
        return null;
    }

    return (
        <Modal
            className={{
                'modal__icon-close': cn('flow-modal__icon-close'),
                'modal__children'  : cn('flow-modal__children'),
                'modal__body'      : cn('flow-modal__body')
            }}
            onClose={() => {
                closed();
            }}
        >
            <FlowSteps />
        </Modal>
    );
};
