import { useMemo } from 'react';

import { useDispatch } from '#core/store';

import { slice } from '#component/wagmi/flow-modal/slice';

export const useWeb3Modal = () => {
    const dispatch = useDispatch();

    return useMemo(() => ({
        open: () => {
            dispatch(
                slice.actions.set({
                    isOpen: true
                })
            );
        }
    }), []);
};
