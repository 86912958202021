
import * as amplitudeSource from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import { autocapturePlugin } from '@amplitude/plugin-autocapture-browser';
import { watchAccount } from 'wagmi/actions';
import { useState, useEffect } from 'react';

import { useWagmiFlow } from '#hook/use-wagmi-flow';
import { config } from '#core/wagmi';
import { getDataTWA } from '#hook/get-data-twa';

export const useAmplitude = () => {
    const { isLoading, hasNeedConnectWallet } = useWagmiFlow();
    const [amplitude, setAmplitude] = useState<typeof amplitudeSource | null>(null);
    const { isTelegram } = getDataTWA() || { isTelegram: false };

    useEffect(() => {
        if(!hasNeedConnectWallet && !isLoading && !amplitude && import.meta.env.APP_AMPLITUDE) {
            void amplitudeSource
                .init(import.meta.env.APP_AMPLITUDE)
                .promise
                .then(() => {
                    watchAccount(config, {
                        onChange(data) {
                            if(data.status === 'connecting') {
                                if(isTelegram) {
                                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                                    instance.track('tgapp_wallet_connect_started');
                                } else {
                                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                                    instance.track('wallet_connect_started');
                                }
                            } else if(data.status === 'connected') {
                                if(isTelegram) {
                                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                                    instance.track('tgapp_wallet_connected', {
                                        wallet_name: data.connector.name
                                    });
                                } else {
                                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                                    instance.track('wallet_connected', {
                                        wallet_name: data.connector.name
                                    });
                                }
                            }
                        }
                    });
                });

            amplitudeSource.add(
                sessionReplayPlugin({
                    sampleRate: 1
                })
            );

            amplitudeSource.add(autocapturePlugin());

            const instance = amplitudeSource;

            setAmplitude(instance);
        }
    }, [hasNeedConnectWallet, isLoading, amplitude]);

    const track = (eventName: string, eventProperties?: Record<string, unknown>) => {
        if(!hasNeedConnectWallet && amplitude) {
            amplitude.track(eventName, eventProperties);
        }
    };

    return { track };
};
