import React from 'react';

// Lazy load the components
const AirdropsPage = React.lazy(() => import('./pages/airdrops'));
const ClaimPage = React.lazy(() => import('./pages/claim'));
const FriendsPage = React.lazy(() => import('./pages/friends'));
const ProfilePage = React.lazy(() => import('./pages/profile'));

// Define a type for lazy-loaded components
type TLazyComponent = React.LazyExoticComponent<React.FC>;

// Define a type for the components dictionary
interface IComponentsDictionary {
    airdrops: TLazyComponent,
    claim: TLazyComponent,
    friends: TLazyComponent,
    profile: TLazyComponent
}

// Export the components in a dictionary
export const componentsDictionary: IComponentsDictionary = {
    airdrops: AirdropsPage,
    claim   : ClaimPage,
    friends : FriendsPage,
    profile : ProfilePage
};
