import { readContracts, getAccount } from 'wagmi/actions';

import type * as apiV1IgosGet from '#adapter/api/main/api/v1/igos/get';
import type * as apiV1IgosIgoIdGet from '#adapter/api/main/api/v1/igos/{igo_id}/get';
import { config } from '#core/wagmi';
import abiIgo from '#abi/igo';
import abiUsdt from '#abi/usdt';

import { api } from '.';

interface IGetRound {
    id: bigint,
    date: bigint,
    releasedAt: bigint,
    totalSupply: bigint,
    minimumRaised: bigint,
    fundsRaised: bigint,
    sold: bigint,
    step: number,
    suspended: boolean
}

interface IGetStepSale {
    allowances: Array<bigint>,
    chains: Array<{
        id: bigint,
        name: string
    }>,
    date: bigint,
    end: bigint,
    fundsRaised: bigint,
    onSale: bigint,
    price: bigint,
    sold: bigint,
    start: bigint,
    targetAllowances: Array<bigint>
}

export const apiInject = api.injectEndpoints({
    endpoints: (build) => ({
        getListIGO: build.query<Array<apiV1IgosGet.TCode200[number] & { getRound: { status: string, result: any } }>, apiV1IgosGet.IParameters | void>({
            queryFn: async (_args, _api) => {
                const data = await _api.dispatch(api.endpoints.apiV1IgosGet.initiate(_args)).unwrap();

                const contracts = data
                    .map((item) => ({
                        address     : item.address as `0x${string}`,
                        abi         : abiIgo,
                        functionName: 'getRound'
                    }));

                const result = await readContracts(config, {
                    allowFailure: true,
                    contracts
                });

                return {
                    data: data.map((item, index) => ({
                        ...item,
                        getRound: {
                            status: result[index]?.status,
                            result: result[index]?.result
                        }
                    }))
                };
            }
        }),
        getIgo: build.query<apiV1IgosIgoIdGet.ICode200 & { getRound?: IGetRound, getPreSale?: IGetStepSale, getActiveSale?: IGetStepSale, decimals?: number, getAcceptedToken?: `0x${string}`, symbol?: string, investmentOf?: { investedFunds: bigint, refundedFunds: bigint, tokens: bigint } }, apiV1IgosIgoIdGet.IParameters>({
            queryFn: async (_args, _api) => {
                const igo = await _api.dispatch(api.endpoints.apiV1IgosIgoIdGet.initiate(_args)).unwrap();

                try {
                    const [getRound, getAcceptedToken] = await readContracts(config, {
                        allowFailure: true,
                        contracts   : [{
                            address     : igo.address as `0x${string}`,
                            abi         : abiIgo,
                            functionName: 'getRound'
                        }, {
                            address     : igo.address as `0x${string}`,
                            abi         : abiIgo,
                            functionName: 'getAcceptedToken'
                        }]
                    });

                    if(getRound.status === 'success') {
                        const contracts = [];

                        if(getAcceptedToken.status === 'success') {
                            contracts.push({
                                address     : getAcceptedToken.result,
                                abi         : abiUsdt,
                                functionName: 'decimals'
                            }, {
                                address     : getAcceptedToken.result,
                                abi         : abiUsdt,
                                functionName: 'symbol'
                            });
                        }

                        if(getRound.result.step === 1) {
                            contracts.push({
                                address     : igo.address as `0x${string}`,
                                abi         : abiIgo,
                                functionName: 'getPreSale'
                            });
                        } else if(getRound.result.step === 2) {
                            contracts.push({
                                address     : igo.address as `0x${string}`,
                                abi         : abiIgo,
                                functionName: 'getActiveSale'
                            });
                        } else if(getRound.result.step === 4) {
                            const account = getAccount(config);

                            if(account.address && account.isConnected) {
                                contracts.push({
                                    address     : igo.address as `0x${string}`,
                                    abi         : abiIgo,
                                    functionName: 'investmentOf',
                                    args        : [getRound.result.id, account.address]
                                });
                            }
                        }

                        if(contracts.length) {
                            const responses = await readContracts(config, {
                                allowFailure: true,
                                contracts
                            });

                            const result = contracts.reduce<Record<string, any>>((acc, current, index) => {
                                if(responses[index].status === 'success') {
                                    acc[current.functionName] = responses[index].result;
                                }

                                return acc;
                            }, {});

                            return {
                                data: {
                                    ...igo,
                                    ...result,
                                    getRound        : getRound.result,
                                    getAcceptedToken: getAcceptedToken.result
                                }
                            };
                        }
                    }

                    return {
                        data: {
                            ...igo,
                            getRound        : getRound.result,
                            getAcceptedToken: getAcceptedToken.result
                        }
                    };
                } catch(error) {
                    console.warn('getRound ERROR:', error);
                }

                return {
                    data: igo
                };
            }
        })
    })
});
