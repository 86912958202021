import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';

import { useClassnames } from '#hook/use-classnames';
import { numberFormatWithDecimals } from '#helper/usd-number-format';
import { useSelector } from '#core/store';

import IconInfoWithCircle from '#component/icons/info-with-circle';
import { slice as sliceMediaQuery } from '#component/media-query/slice';

import style from './index.module.pcss';

export interface ITableItem {
    id: number,
    game: string,
    amount: string,
    token: string
}

interface IProps {
    value: string,
    token?: string,
    tooltip?: boolean,
    maxDecimals?: number
}

const MAX_DECIMALS = 5;

export const RenderAmountField = ({ value, token, tooltip = true, maxDecimals = MAX_DECIMALS }: IProps) => {
    const cn = useClassnames(style);
    const { t } = useTranslation(undefined, {
        keyPrefix: 'routes.claim'
    });
    const isDesktop = useSelector((store) => store[sliceMediaQuery.name].isDesktop);

    const elTooltip = (sumValue: string) => {
        return (
            <Tooltip
                className={cn('amount__tooltip')}
                title={t('table.default.tooltip', {
                    percent: '2.5',
                    sum    : numberFormatWithDecimals(parseFloat(sumValue), 12)
                })}
            >
                <span />
                <IconInfoWithCircle />
            </Tooltip>
        );
    };

    return (
        <div
            className={cn('amount', {
                'amount__with-tooltip': tooltip
            })}
        >
            <strong className={cn('amount__val')}>
                {numberFormatWithDecimals(parseFloat(value), maxDecimals)}
            </strong>
            <div className={cn('amount__token')}>
                {token && `$${token}`}
                {isDesktop && tooltip && elTooltip(value)}
            </div>
        </div>
    );
};
