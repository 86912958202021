import React, { Suspense, useEffect, useLayoutEffect, useMemo } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

import { useWagmiFlow } from '#hook/use-wagmi-flow';
import api from '#adapter/api/main';
import { useSelector } from '#core/store';
import { getDataTWA } from '#hook/get-data-twa';

import { slice as bannerSlice } from '#component/banner/slice';
import { slice as wagmiFlowModalSlice } from '#component/wagmi/flow-modal/slice';
import { slice as sliceGTM } from '#component/gtm/provider/slice';
import { useAffilateLink } from '#component/referral/hooks/use-affilate-link';
import { Component as SignIn } from '#route/sign-in';

const CONTRATS_PAGE_REDIRECT_COUNT_COOKIE_NAME = 'congrats_page_redirect_count';

export const ManipulationLayout = () => {
    useAffilateLink();
    const navigate = useNavigate();
    const location = useLocation();
    const { isTelegram } = getDataTWA() || { isTelegram: false };
    const { hasNeedFlow, hasNeedConnectWallet, hasNeedSign, isLoading: isLoadingWagmiFlow } = useWagmiFlow();
    const { data: user } = api.endpoints.apiV1UsersWhoamiGet.useQuery();
    const { inited: gtmInited } = useSelector((state) => state[sliceGTM.name]);
    const { isOpen: isOpenFlowModal } = useSelector((state) => state[wagmiFlowModalSlice.name]);
    const { opened: isOpenBanner } = useSelector((state) => state[bannerSlice.name]);
    const { data: congratsPermit, isLoading: isLoadingCongratsPermit } = api.useApiV1TasksRewardsGetQuery(undefined, {
        skip            : hasNeedFlow,
        selectFromResult: ({ data, ...restPayload }) => ({
            ...restPayload,
            data: data?.active_permits.find((p) => p?.kind === 'congrats')
        })
    });

    // Scroll to top if path changes
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    // Если юзер залогинен и у него есть пермит на коннект кошелька, нужно перекинуть на страницу поздравлений
    const needNavigateToCongratulations = useMemo(
        () => user && !hasNeedFlow && congratsPermit && !isLoadingWagmiFlow && !isLoadingCongratsPermit,
        [user, hasNeedFlow, congratsPermit, isLoadingWagmiFlow, isLoadingCongratsPermit]
    );

    useEffect(() => {
        const redirectCountCookie = Cookies.get(CONTRATS_PAGE_REDIRECT_COUNT_COOKIE_NAME);
        const redirectCount = redirectCountCookie ? parseInt(redirectCountCookie, 10) : 0;

        if(needNavigateToCongratulations && redirectCount < 2 && !isTelegram) {
            navigate('/congratulations');
            Cookies.set(CONTRATS_PAGE_REDIRECT_COUNT_COOKIE_NAME, String(redirectCount + 1));
        }
    }, [needNavigateToCongratulations, navigate, isTelegram]);

    // GTM не дает уничтожать инстанс из коробки, поэтому приходится перезагружать страницу когда меняется состояние авторизации
    // При этом надо учитывать, что если выполнено условие needNavigateToCongratulations, то перезагружать страницу не нужно
    useEffect(() => {
        const authChanged = (gtmInited === 'authorized' && hasNeedConnectWallet) || (gtmInited === 'unauthorized' && !hasNeedConnectWallet);

        if(!isOpenFlowModal && authChanged && !isOpenBanner) {
            window.location.reload();
        }
    }, [hasNeedConnectWallet, isOpenFlowModal, gtmInited, isOpenBanner, isTelegram]);

    return (
        <Suspense fallback={null}>
            {(!hasNeedConnectWallet && hasNeedSign) ? <SignIn /> : <Outlet />}
        </Suspense>
    );
};
