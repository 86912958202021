import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IState {
    isOpen: boolean
}

const initialState: IState = {
    isOpen: false
};

export const slice = createSlice({
    initialState,
    name    : 'flow-modal',
    reducers: {
        reset: () => initialState,
        set  : (state, action: PayloadAction<Partial<IState>>) => {
            return {
                ...state,
                ...action.payload
            };
        }
    }
});
