import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { type TStyle, useClassnames } from '#hook/use-classnames';

import style from './index.module.pcss';

export interface IProps {
    readonly className?: TStyle | string,
    readonly presetSize?: 'default' | 'big',
    readonly isWithDescription?: boolean
}

export const SocialBar = ({ presetSize = 'default', isWithDescription = true, ...props }: IProps) => {
    const cn = useClassnames(style, props.className);
    const { t } = useTranslation();

    const elTelegramIcon = useMemo(() => {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 30 26"
                className={cn('social-bar__icon', 'social-bar__icon_telegram')}
            >
                <defs>
                    <linearGradient
                        id="a"
                        x1=".613"
                        x2=".805"
                        y1=".471"
                        y2=".837"
                    >
                        <stop stopColor="#EFF7FC" />
                        <stop
                            offset="1"
                            stopColor="#fff"
                        />
                    </linearGradient>
                </defs>
                <path
                    fill="#C8DAEA"
                    d="M11.763 22.63c-.78 0-.64-.3-.92-1.04l-2.28-7.52 14.16-8.84 1.66.44-1.38 3.76-11.24 13.2z"
                />
                <path
                    fill="#A9C9DD"
                    d="M11.764 22.635c.6 0 .86-.28 1.2-.6.52-.5 7.2-7 7.2-7l-4.1-1-3.8 2.4-.5 6v.2z"
                />
                <path
                    fill="url(#a)"
                    d="m12.163 16.505 9.68 7.14c1.1.6 1.9.3 2.18-1.02l3.94-18.56c.4-1.62-.62-2.34-1.68-1.86l-23.12 8.92c-1.58.64-1.56 1.52-.28 1.9l5.94 1.86 13.74-8.66c.64-.4 1.24-.18.76.26l-11.16 10.02z"
                />
            </svg>
        );
    }, [cn]);

    const elTelegramChannel = useMemo(() => {
        return (
            <a
                href="https://t.me/playweb3_io"
                className={cn('social-bar__link-item')}
                target="_blank"
                title={t('components.social-bar.telegram-ch')}
                rel="noreferrer"
            >
                <div className={cn('social-bar__icon-wrap')}>
                    {elTelegramIcon}
                </div>
                {isWithDescription && (
                    <p>{t('components.social-bar.telegram-ch')}</p>
                )}
            </a>
        );
    }, [t, cn, elTelegramIcon, isWithDescription]);

    const elTelegramCommunity = useMemo(() => {
        return (
            <a
                href="https://t.me/playweb3_io_chat"
                className={cn('social-bar__link-item')}
                target="_blank"
                title={t('components.social-bar.telegram-m')}
                rel="noreferrer"
            >
                <div className={cn('social-bar__icon-wrap')}>
                    {elTelegramIcon}
                    <span>CHAT</span>
                </div>
                {isWithDescription && (
                    <p>{t('components.social-bar.telegram-m')}</p>
                )}
            </a>
        );
    }, [elTelegramIcon, cn, t, isWithDescription]);

    const elXElement = useMemo(() => {
        return (
            <a
                href="https://x.com/playweb3_io"
                className={cn('social-bar__link-item')}
                target="_blank"
                title={t('components.social-bar.twitter')}
                rel="noreferrer"
            >
                <div className={cn('social-bar__icon-wrap')}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        className={cn('social-bar__icon')}
                    >
                        <path
                            d="M11.852 8.511 19.09.271h-1.715l-6.289 7.153L6.07.27H.279l7.59 10.82-7.59 8.64h1.714l6.637-7.557 5.301 7.557h5.79M2.613 1.537h2.635l12.128 16.988H14.74"
                        />
                    </svg>
                </div>
                {isWithDescription && (
                    <p>{t('components.social-bar.twitter')}</p>
                )}
            </a>
        );
    }, [cn, t, isWithDescription]);

    const elDiscord = useMemo(() => {
        return (
            <a
                href="https://discord.gg/playweb3"
                className={cn('social-bar__link-item')}
                target="_blank"
                title={t('components.social-bar.discord')}
                rel="noreferrer"
            >
                <div className={cn('social-bar__icon-wrap')}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 25 20"
                        className={cn('social-bar__icon', 'social-bar__icon_discord')}
                    >
                        <path
                            d="M21.177 1.619a20.362 20.362 0 0 0-5.158-1.621c-.222.402-.481.942-.66 1.372a18.938 18.938 0 0 0-5.717 0 14.66 14.66 0 0 0-.668-1.372 20.294 20.294 0 0 0-5.162 1.625C.548 6.555-.337 11.365.106 16.107c2.165 1.617 4.263 2.599 6.325 3.242.51-.701.964-1.447 1.355-2.232a13.323 13.323 0 0 1-2.133-1.038c.179-.133.354-.271.523-.414 4.113 1.924 8.583 1.924 12.647 0 .171.143.346.281.523.414-.676.408-1.392.757-2.137 1.04.391.784.843 1.53 1.355 2.232a20.582 20.582 0 0 0 6.33-3.244c.518-5.497-.887-10.263-3.717-14.488ZM8.347 13.19c-1.235 0-2.248-1.153-2.248-2.557s.991-2.558 2.248-2.558c1.256 0 2.269 1.152 2.247 2.558.002 1.404-.99 2.557-2.247 2.557Zm8.305 0c-1.235 0-2.247-1.153-2.247-2.557s.99-2.558 2.247-2.558 2.27 1.152 2.248 2.558c0 1.404-.991 2.557-2.248 2.557Z"
                        />
                    </svg>
                </div>
                {isWithDescription && (
                    <p>{t('components.social-bar.discord')}</p>
                )}
            </a>
        );
    }, [cn, t, isWithDescription]);

    return (
        <div
            className={cn('social-bar', {
                [`social-bar_${presetSize}`]: presetSize !== 'default'
            })}
        >
            {elXElement}
            {elDiscord}
            {elTelegramChannel}
            {elTelegramCommunity}
        </div>
    );
};
