import React from 'react';
import { RouteObject } from 'react-router-dom';

import { Layout } from '#component/layout';
import { ManipulationLayout } from '#component/layout/location-manipulation';
import { ErrorBoundaryRouter } from '#component/error-boundary/router';

import TelegramLayout from './telegram';
import { componentsDictionary } from './telegram/components';

export const SIGN_IN_PATH = '/sign-in';

export const COMMON: Array<RouteObject> = [
    {
        element      : <ManipulationLayout />,
        ErrorBoundary: ErrorBoundaryRouter,
        children     : [
            {
                element : <Layout />,
                children: [
                    {
                        path: '/',
                        lazy: () => import('./main')
                    },
                    {
                        path: '/launchpad',
                        lazy: () => import('./launchpad')
                    },
                    {
                        path    : '/launchpad/:id',
                        lazy    : () => import('./launchpad/{id}/layout'),
                        children: [{
                            index: true,
                            lazy : () => import('./launchpad/{id}')
                        }, {
                            path: 'buy',
                            lazy: () => import('./launchpad/{id}/buy')
                        }]
                    },
                    {
                        path: '/games',
                        lazy: () => import('./games')
                    },
                    {
                        path: '/games/:id',
                        lazy: () => import('./games/{id}')
                    },
                    {
                        path: '/games/:id/swap',
                        lazy: () => import('./games/{id}/swap')
                    },
                    {
                        path: '/for-blockchains',
                        lazy: () => import('./for-blockchains')
                    },
                    {
                        path: '/for-token-owners',
                        lazy: () => import('./for-tokens')
                    },
                    {
                        path: '/for-gamers',
                        lazy: () => import('./for-gamers')
                    },
                    {
                        path: '/for-investors',
                        lazy: () => import('./for-investors')
                    },
                    {
                        path: '/for-developers',
                        lazy: () => import('./for-developers')
                    },
                    {
                        path: '/claim/:id?',
                        lazy: () => import('./claim')
                    },
                    {
                        path: '/trade',
                        lazy: () => import('./game-tokens')
                    },
                    {
                        path: '/about',
                        lazy: () => import('./about')
                    },
                    {
                        path: '/airdrops',
                        lazy: () => import('./airdrops')
                    },
                    {
                        path: '/game/:gameId/airdrops/:airdropId',
                        lazy: () => import('./airdrops/{id}')
                    },
                    {
                        path: '/nft',
                        lazy: () => import('./nft')
                    },
                    {
                        path: '/referral',
                        lazy: () => import('./referral')
                    },
                    {
                        path: '/developers/:id',
                        lazy: () => import('./developers')
                    },
                    {
                        path: '/profile',
                        lazy: () => import('./profile')
                    },
                    {
                        path: '/news',
                        lazy: () => import('./news')
                    },
                    {
                        path: '/news/:id',
                        lazy: () => import('./news/{id}')
                    },
                    {
                        path: '/token/:id',
                        lazy: () => import('./token')
                    },
                    {
                        path: '/lp-prelaunch',
                        lazy: () => import('./cpm')
                    },
                    {
                        path: '/playweb3-stats',
                        lazy: () => import('./playweb3-stats')
                    },
                    {
                        path: '/contact-us',
                        lazy: () => import('./contact-us')
                    },
                    {
                        path: '/all-developers',
                        lazy: () => import('./all-developers')
                    },
                    {
                        path: '/terms-and-conditions/:type',
                        lazy: () => import('./terms-and-conditions')
                    },
                    {
                        path: '/congratulations',
                        lazy: () => import('./congratulations')
                    },
                    {
                        path: '/roadmap',
                        lazy: () => import('./roadmap')
                    },
                    {
                        path: '/tasks',
                        lazy: () => import('./tasks')
                    },
                    {
                        path: '/sign-in',
                        lazy: () => import('./sign-in')
                    },
                    {
                        path: '/pw3-token',
                        lazy: () => import('./pw3-token')
                    },
                    {
                        path: '*',
                        lazy: () => import('./404')
                    }
                ]
            }
        ]
    },
    {
        element : <ManipulationLayout />,
        children: [
            {
                path    : '/telegram',
                element : <TelegramLayout />,
                children: [
                    {
                        path   : 'airdrops',
                        element: React.createElement(componentsDictionary.airdrops)
                    },
                    {
                        path   : 'claim',
                        element: React.createElement(componentsDictionary.claim)
                    },
                    {
                        path   : 'friends',
                        element: React.createElement(componentsDictionary.friends)
                    },
                    {
                        path   : 'profile',
                        element: React.createElement(componentsDictionary.profile)
                    }
                ]
            }
        ]
    }
];
