import React, { ReactNode } from 'react';
import { notification } from 'antd';
import i18n from 'i18next';

const DEFAULT_DURATION = 10;

export interface IError {
    data: string | { detail: string },
    error: string,
    originalStatus: number,
    status: string
}

export const useNotification = () => {
    const methods = {
        error: (content: ReactNode, duration?: number) => {
            const type = 'error';
            const defaultContent = !content ? i18n.t('components.notifications.error') : content;

            void notification.error({
                message : <div children={defaultContent} />,
                type,
                duration: duration || DEFAULT_DURATION
            });
        },
        warning: (content: ReactNode, duration?: number) => {
            const type = 'warning';
            const defaultContent = !content ? i18n.t('components.notifications.error') : content;

            void notification.success({
                message : <div children={defaultContent} />,
                type,
                duration: duration || DEFAULT_DURATION
            });
        },
        success: (content: ReactNode, duration?: number) => {
            const type = 'success';
            const defaultContent = !content ? i18n.t('components.notifications.error') : content;

            void notification.success({
                message : <div children={defaultContent} />,
                type,
                duration: duration || DEFAULT_DURATION
            });
        }
    };

    return { methods };
};
