import React, { Fragment, useMemo, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { lock, unlock } from 'tua-body-scroll-lock';

import { type TStyle, useClassnames } from '#hook/use-classnames';
import { useDispatch, useSelector } from '#core/store';

import { IconMenuArrow } from '#component/icons/menu-arrow';
import { SocialBar } from '#component/social-bar';
import { Countdown, IProps as ICountdownProps } from '#component/countdown';
import { slice as sliceBanner } from '#component/banner/slice';

import { MENU_LINKS } from '#const';

import { slice } from './slice';

import style from './index.module.pcss';

export interface IProps {
    readonly className?: TStyle | string,
    readonly igoStartDate?: string | null,
    readonly igoStatus?: string | null
}

export const Menu = (props: IProps) => {
    const cn = useClassnames(style, props.className);
    const { t } = useTranslation();
    const $content = useRef<HTMLDivElement>(null);
    const location = useLocation();
    const dispatch = useDispatch();
    const opened = useSelector((state) => state[slice.name].opened);
    const hasClaimAvailable = useSelector((store) => store.claims.hasClaimAvailable);

    const elIcon = useMemo(() => {
        if(opened) {
            return (
                <Fragment>
                    <path
                        fill="#000"
                        d="m10.555 11.99.99-.99 9.9 9.9-.99.99-9.9-9.9Z"
                    />
                    <path
                        fill="#000"
                        d="m11.545 21.89-.99-.99 9.9-9.9.99.99-9.9 9.9Z"
                    />
                </Fragment>
            );
        }

        return (
            <path
                fill="#000"
                d="M8.889 14.666v-1.333h13.777v1.333H8.89Zm0 4v-1.333h13.777v1.333H8.89Z"
            />
        );
    }, [opened]);

    const elContent = useMemo(() => {
        if(opened) {
            return (
                <div
                    ref={$content}
                    className={cn('menu__content')}
                >
                    <ul className={cn('menu__links')}>
                        {MENU_LINKS.map((link) => (
                            <li
                                key={link}
                                className={cn('menu__link-item')}
                                data-key={link}
                            >
                                <Link
                                    to={`/${link}`}
                                    title={t(`components.menu.links.${link}`)}
                                    className={cn('menu__link')}
                                    onClick={() => {
                                        dispatch(slice.actions.set(false));
                                    }}
                                >
                                    <IconMenuArrow />
                                    <p className={cn('menu__link-text')}>
                                        {t(`components.menu.links.${link}`)}
                                        {link === 'claim' && hasClaimAvailable && (
                                            <span className={`${cn('menu__notification-dot')} ${cn('menu__notification-dot--link')}`} />
                                        )}
                                    </p>
                                </Link>
                            </li>
                        ))}
                    </ul>
                    {(props.igoStartDate) && (
                        <div
                            className={cn('menu__countdown-wrapper')}
                            onClick={() => dispatch(sliceBanner.actions.set(true))}
                            role="button"
                            tabIndex={0}
                        >
                            <h3 className={cn('menu__countdown-title')}>
                                {t('components.menu.countdown')}
                            </h3>
                            <Countdown
                                className={cn('menu__countdown')}
                                date={new Date(props.igoStartDate)}
                                status={props.igoStatus as ICountdownProps['status']}
                            />
                        </div>
                    )}
                    <SocialBar />
                </div>
            );
        }

        if(hasClaimAvailable) {
            return <span className={`${cn('menu__notification-dot')} ${cn('menu__notification-dot--menu')}`} />;
        }
    }, [cn, t, opened, props.igoStartDate, hasClaimAvailable]);

    useEffect(() => {
        if(opened) {
            lock($content.current, {
                overflowType      : 'clip',
                useGlobalLockState: true
            });
        } else {
            unlock($content.current, {
                useGlobalLockState: true
            });
        }

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            unlock($content.current, {
                useGlobalLockState: true
            });
        };
    }, [opened]);

    useEffect(() => {
        dispatch(slice.actions.set(false));
    }, [location.pathname]);

    return (
        <div className={cn('menu')}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                fill="none"
                className={cn('menu__button')}
                children={elIcon}
                onClick={() => {
                    dispatch(slice.actions.set(!opened));
                }}
            />
            {elContent}
        </div>
    );
};
