import React from 'react';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';

import { useClassnames } from '#hook/use-classnames';
import { useSelector } from '#core/store';

import { slice as sliceMediaQuery } from '#component/media-query/slice';

import { ClaimButton } from '../components/button';
import { RenderAmountField } from '../components/table/render/amount';
import { IData, TKindType } from './combined-data';
import { EWallets, TType } from './use-contract-data';
import { THandleClaimFunction } from './use-handle-claim';

import style from '../pages/game-developer/index.module.pcss';

export interface IContractResult {
    stream: number,
    value: bigint
}
// судя по макету так, но уберем если что
const VESTED_DECIMALS = 0;
const DEFUALT_DECIMALS = 9;

export enum EClaimStatus {
    MakingTransaction = 'makingTransaction',
    Waiting = 'waiting',
    Success = 'success',
    Error = 'error',
    WalletNotConnected = 'walletNotConnected',
    NoPermit = 'noPermit',
    NoTransactionId = 'noTransactionId'
}

export const STATUS_TYPES: Record<EClaimStatus, 'success' | 'error'> = {
    [EClaimStatus.MakingTransaction] : 'success',
    [EClaimStatus.Waiting]           : 'success',
    [EClaimStatus.Success]           : 'success',
    [EClaimStatus.Error]             : 'error',
    [EClaimStatus.WalletNotConnected]: 'error',
    [EClaimStatus.NoPermit]          : 'error',
    [EClaimStatus.NoTransactionId]   : 'error'
};

export const DISABLED_STATUSES = new Set<EClaimStatus>([
    EClaimStatus.MakingTransaction,
    EClaimStatus.Waiting
]);

// Определяем kindToLocaleKey с использованием этого типа
const kindToLocaleKey: Record<TKindType, string> = {
    game : 'In Game Items Sale',
    taxes: 'Trading Taxes Distribution',
    igo  : 'LaunchPad'
};

const generateUniqueId = (type: TType, id: number, kind?: string | null): string => {
    // Если `kind` существует, добавляем его с тире, иначе только `type` и `id`
    return `${kind ? `${kind}-` : ''}${type}-${id}`;
};

export type TDisabledMap = Record<string, EClaimStatus>;
export type THandleChange = (uniqueId: string, status: EClaimStatus, customMessage?: string) => void;
export const useColumns = (
    handleClaim: THandleClaimFunction,
    statusMap: TDisabledMap,
    type: TType,
    handleStatusChange: THandleChange,
    walletFromPath?: TType
): ColumnsType<IData> => {
    const isDesktop = useSelector((store) => store[sliceMediaQuery.name].isDesktop);
    const cn = useClassnames(style);
    const isVesting = type === EWallets.VestingContract;
    const { t } = useTranslation();

    const commonColumns: ColumnsType<IData> = [
        {
            title    : t('routes.claim.table.vested-game-tokens.columns.game.name'),
            dataIndex: 'full_name',
            width    : isDesktop ? 170 : 110,
            key      : 'full_name',
            render   : (value: string, record: IData) => (
                <React.Fragment>
                    <p>{value}</p>
                    <p>
                        $
                        {record?.token_name}
                    </p>
                </React.Fragment>
            )
        },
        {
            title    : t('routes.claim.table.vested-game-tokens.columns.ready-to-claim'),
            dataIndex: 'readyToClaim',
            key      : 'readyToClaim',
            hidden   : isDesktop,
            align    : 'center' as const,
            render   : (value: string, record: IData) => {
                const { id, transaction_id, claim_wallet, token_name, kind, short_name, token_id } = record;
                const uniqueId = generateUniqueId(type, id, kind); // Генерация уникального ID
                const disabled = DISABLED_STATUSES.has(statusMap[uniqueId]);

                return (
                    <div className={cn('claim-page__ready-to-claim')}>
                        <RenderAmountField
                            value={value}
                            // token={token_name || ''}
                            maxDecimals={type === EWallets.VestingContract ? VESTED_DECIMALS : DEFUALT_DECIMALS}
                            tooltip={false}
                        />
                        <ClaimButton
                            buttonProps={{
                                children: 'Claim',
                                disabled: disabled || record.disabled,
                                onClick : () => handleClaim({
                                    transaction_id,
                                    claim_wallet,
                                    token_name,
                                    game_id   : id,
                                    game_name : short_name,
                                    token_id,
                                    claim_type: kind ? kindToLocaleKey[kind] : 'Vested Game Tokens Distribution',
                                    type
                                }, (status, customMessage) => handleStatusChange(uniqueId, status, customMessage)),
                                presetSize: 'extra-small'
                            }}
                        />
                    </div>
                );
            }
        },
        {
            title    : t('routes.claim.table.vested-game-tokens.columns.allocated'),
            dataIndex: 'tokenAllocated',
            key      : 'tokenAllocated',
            width    : 124,
            hidden   : !isVesting,
            align    : 'center' as const,
            render   : (value: number) => (
                <RenderAmountField
                    value={value.toString()}
                    tooltip={false}
                    maxDecimals={VESTED_DECIMALS}
                />
            )
        },
        {
            title    : t('routes.claim.table.vested-game-tokens.columns.claimed'),
            dataIndex: 'claimed',
            key      : 'claimed',
            width    : isDesktop ? 124 : 100,
            hidden   : !isDesktop,
            align    : 'center' as const,
            render   : (value: number) => (
                <RenderAmountField
                    value={value.toString()}
                    tooltip={false}
                    maxDecimals={type === EWallets.VestingContract ? VESTED_DECIMALS : DEFUALT_DECIMALS}
                />
            )
        },
        {
            title    : t('routes.claim.table.vested-game-tokens.columns.vested'),
            dataIndex: 'tokensStillVested',
            key      : 'tokensStillVested',
            width    : 124,
            align    : 'center' as const,
            hidden   : !isVesting,
            render   : (value: number) => (
                <RenderAmountField
                    value={value.toString()}
                    tooltip={false}
                    maxDecimals={VESTED_DECIMALS}
                />
            )
        },
        {
            title    : t('routes.claim.table.vested-game-tokens.columns.ready-to-claim'),
            dataIndex: 'readyToClaim',
            key      : 'readyToClaim',
            hidden   : !isDesktop,
            align    : 'center' as const,
            render   : (value: string) => (
                <div className={cn('claim-page__ready-to-claim')}>
                    <RenderAmountField
                        value={value}
                        maxDecimals={type === EWallets.VestingContract ? VESTED_DECIMALS : DEFUALT_DECIMALS}
                        tooltip={false}
                    />
                </div>
            )
        },
        {
            title : t('routes.claim.table.default.button-claim'),
            key   : 'action',
            hidden: !isDesktop,
            align : 'center' as const,
            render: (value: string, record: IData) => {
                const { id, transaction_id, claim_wallet, kind, short_name, token_id, token_name } = record;
                const uniqueId = generateUniqueId(type, id, kind); // Генерация уникального ID

                const disabled = DISABLED_STATUSES.has(statusMap[uniqueId]);

                return (
                    <ClaimButton
                        buttonProps={{
                            children: 'Claim',
                            disabled: disabled || record.disabled,
                            onClick : () => handleClaim({
                                transaction_id,
                                claim_wallet,
                                token_name,
                                game_id   : id,
                                game_name : short_name,
                                token_id,
                                claim_type: kind ? kindToLocaleKey[kind] : 'Vested Game Tokens Distribution',
                                type      : (walletFromPath && isVesting) ? walletFromPath : type
                            }, (status, customMessage) => handleStatusChange(uniqueId, status, customMessage)),
                            presetSize: 'extra-small'
                        }}
                    />
                );
            }
        }
    ];

    return commonColumns;
};
