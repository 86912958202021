import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IClaim {
    token?: string | null,
    stream?: number | null,
    value: string
}

interface IState {
    availableClaims: Array<IClaim>,
    hasClaimAvailable: boolean,
    lastUpdated: number | null // Time of the last update in milliseconds
}

const initialState: IState = {
    availableClaims  : [],
    hasClaimAvailable: false,
    lastUpdated      : null
};

export const slice = createSlice({
    name    : 'claims',
    initialState,
    reducers: {
        reset             : () => initialState,
        setAvailableClaims: (state, action: PayloadAction<{ claims: Array<IClaim>, timestamp: number }>) => {
            return {
                ...state,
                availableClaims  : action.payload.claims,
                hasClaimAvailable: action.payload.claims.some((claim) => BigInt(claim.value) > 0n),
                lastUpdated      : action.payload.timestamp
            };
        }
    }
});

export const { reset, setAvailableClaims } = slice.actions;
export default slice.reducer;
