import { http, createConfig } from 'wagmi';
import { bsc, bscTestnet } from 'wagmi/chains';
import { injected, metaMask, walletConnect/*, coinbaseWallet*/, type MetaMaskParameters } from 'wagmi/connectors';

import { getDataTWA } from '#hook/get-data-twa';

export const CHAIN_ID = import.meta.env.APP_WAGMI_CHAINS_ID;

export const SUPPORTED_CHAINS = {
    [bsc.id]       : bsc,
    [bscTestnet.id]: bscTestnet
} as const;

export const CHAIN = SUPPORTED_CHAINS[CHAIN_ID || '56'] as typeof bsc;

const twaData = getDataTWA();

const metaMaskParams: MetaMaskParameters = {
    enableAnalytics: false,
    extensionOnly  : true
};

if(twaData?.platform === 'android' || twaData?.platform === 'ios') {
    metaMaskParams.openDeeplink = (url: string) => {
        window.open(url, '_blank');
    };
}

export const config = createConfig({
    chains    : [CHAIN],
    transports: {
        [CHAIN.id]: http()
    },
    connectors: [
        injected({
            shimDisconnect: true
        }),
        walletConnect({
            telemetryEnabled: false,
            projectId       : import.meta.env.APP_WAGMI_WALLET_CONNECT_PROJECT_ID,
            metadata        : {
                name       : import.meta.env.APP_WAGMI_WALLET_CONNECT_METADATA_NAME,
                description: import.meta.env.APP_WAGMI_WALLET_CONNECT_METADATA_DESCRIPTION,
                url        : import.meta.env.APP_WAGMI_WALLET_CONNECT_METADATA_URL || window.location.origin,
                icons      : [import.meta.env.APP_WAGMI_WALLET_CONNECT_METADATA_ICONS]
            },
            qrModalOptions: {
                themeVariables: {
                    '--wcm-z-index': '9999999'
                }
            }
        }),
        metaMask(metaMaskParams)

        /*,
                coinbaseWallet({
                    appName: import.meta.env.APP_WAGMI_COINBASE_WALLET_APP_NAME
                })*/
    ]
});

export const ready = Promise.all(config.connectors.map((connector) => connector.getProvider()));
