import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
// import { useAccount } from 'wagmi';

import { useClassnames } from '#hook/use-classnames';
import { useSelector } from '#core/store';
import { numeral } from '#adapter/numeral';
import api from '#adapter/api/main';

import { ContentBox } from '#component/content-box';
import { CopyButton } from '#component/copy-button';
import { slice as sliceMediaQuery } from '#component/media-query/slice';
import { ClaimGift } from '#component/claim-gift';

import { StatCard, IProps as IStatsProps } from './components/stat-card';
import { UserStatus } from './components/user-status';

import style from './index.module.pcss';

export const QUERY_PARAMS_REF_KEY = 'ref_key';
const MOCK_USER_STATUS = [{
    id   : 1,
    count: 1,
    title: 'Crypto rookie',
    text : 'You\'ve invited less than 10 users to the platform'
}, {
    id   : 2,
    count: 10,
    title: 'Blockchain buddy',
    text : 'You\'ve invited 10+ users to the platform'
}, {
    id   : 3,
    count: 50,
    title: 'Web3 warrior',
    text : 'You\'ve invited 50+ users to the platform'
}, {
    id   : 4,
    count: 100,
    title: 'Metaverse Master',
    text : 'You\'ve invited 100+ users to the platform'
}];

export const Referral = () => {
    const cn = useClassnames(style);
    const { t } = useTranslation();
    const isDesktop = useSelector((store) => store[sliceMediaQuery.name].isDesktop);
    const { data } = api.endpoints.apiV1UsersWhoamiGet.useQuery();

    const elStatus = () => {
        let userLevel = 1;

        if(data?.affiliate_user_count) {
            if(data.affiliate_user_count > 10) {
                userLevel = 2;
            }

            if(data.affiliate_user_count > 50) {
                userLevel = 3;
            }

            if(data.affiliate_user_count > 100) {
                userLevel = 4;
            }
        }

        if(typeof data?.affiliate_user_level === 'number') {
            return (
                <UserStatus
                    title={MOCK_USER_STATUS[userLevel - 1].title}
                    text={MOCK_USER_STATUS[userLevel - 1].text}
                    count={MOCK_USER_STATUS[userLevel - 1].count}
                />
            );
        }
    };

    const elStats = useMemo(() => {
        const items: Array<IStatsProps & { id: number }> = [
            {
                id     : 1,
                title  : t('components.referral.stats.followed'),
                content: data?.affiliate_links?.reduce((acc, curr) => acc + Number(curr.visits), 0) || 0,
                icon   : 'users-connected'
            }, {
                id     : 2,
                title  : t('components.referral.stats.registered'),
                content: Number(data?.affiliate_user_count),
                icon   : 'user-add'
            }
            // }, {
            //     id     : 3,
            //     title  : t('components.referral.stats.referals'),
            //     content: 1861132,
            //     icon   : 'user-connection'
            // }, {
            //     id     : 4,
            //     title  : t('components.referral.stats.referals-by-network'),
            //     content: 1861132,
            //     icon   : 'user-chained'
            // }
        ];

        return (
            <ContentBox
                className={cn('referral__box-base')}
                header={t('components.referral.stats.title')}
            >
                <div className={cn('referral__box-base-row')}>
                    {items.map((item) => (
                        <StatCard
                            key={item.id}
                            title={item.title}
                            content={numeral(item.content).format('0,0')}
                            icon={item.icon}
                        />
                    ))}
                </div>
                {elStatus()}
            </ContentBox>
        );
    }, [data]);

    const elLink = useMemo(() => {
        if(data?.affiliate_links?.[0]) {
            const link = `https://${window.location.host}?${QUERY_PARAMS_REF_KEY}=${data?.affiliate_links?.[0].link_key}`;

            return (
                <ContentBox className={cn('referral__box-base', 'referral__box-base_no-gap')}>
                    <div className={cn('referral__content-box', 'referral__ref-link')}>
                        <h4 className={cn('referral__ref-link-title')}>
                            {t('components.referral.ref-block.title')}
                        </h4>
                        <div className={cn('referral__ref-link-content')}>
                            <p className={cn('referral__ref-link-value')}>{link}</p>
                            <CopyButton
                                copiedText={t('components.referral.ref-block.copied')}
                                linkToCopy={link}
                                button={{
                                    presetSize: isDesktop ? 'small' : 'default',
                                    className : cn('referral__ref-link-button'),
                                    uppercase : true,
                                    children  : t('components.referral.ref-block.button')
                                }}
                            />
                        </div>
                    </div>
                </ContentBox>
            );
        }
    }, [data?.affiliate_links]);

    if(!data?.affiliate_links?.length) {
        return (
            <div className={cn('referral')}>
                <ContentBox
                    className={cn('referral__box-base')}
                >
                    <div className={cn('referral__content-box')}>
                        {t('components.referral.auth-error')}
                    </div>
                </ContentBox>
            </div>
        );
    }

    return (
        <div className={cn('referral')}>
            <ClaimGift />
            {elLink}
            <ContentBox
                header={t('components.referral.what-block.title')}
                className={cn('referral__box-base')}
            >
                <p className={cn('referral__box-text')}>
                    {t('components.referral.what-block.text')}
                </p>
            </ContentBox>
            {elStats}
        </div>
    );
};
