import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setTelegramData } from '#route/telegram/slice';

export const useTelegramData = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        // Используем `hash` для получения параметров из строки
        const hashParams = new URLSearchParams(window.location.hash.substring(1));
        const searchParams = new URLSearchParams(window.location.search);

        // Извлекаем `ref_key` из search или `tgWebAppStartParam` в hash
        const refKey = searchParams.get('ref_key');
        const tgWebAppDataEncoded = hashParams.get('tgWebAppData');
        const tgWebAppStartParam = hashParams.get('tgWebAppStartParam');
        const tgWebAppVersion = hashParams.get('tgWebAppVersion');
        const tgWebAppPlatform = hashParams.get('tgWebAppPlatform');
        const tgWebAppThemeParamsEncoded = hashParams.get('tgWebAppThemeParams');

        const isTelegram = !!tgWebAppDataEncoded || !!tgWebAppVersion || !!tgWebAppPlatform;

        const tgWebAppDataDecoded = decodeURIComponent(tgWebAppDataEncoded || '');
        const tgWebAppData = new URLSearchParams(tgWebAppDataDecoded);

        const userJson = tgWebAppData.get('user');
        const user = userJson ? JSON.parse(decodeURIComponent(userJson)) : {};

        const tgWebAppThemeParams = tgWebAppThemeParamsEncoded ? JSON.parse(decodeURIComponent(tgWebAppThemeParamsEncoded)) : {};

        // Сохраняем данные в Redux Store
        dispatch(setTelegramData({ user, refKey, tgWebAppVersion, tgWebAppPlatform, tgWebAppThemeParams, isTelegram, tgWebAppStartParam }));
    }, [dispatch]);
};
