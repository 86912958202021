import React, { Fragment } from 'react';
import { Outlet } from 'react-router-dom';

import { useClassnames, type TStyle } from '#hook/use-classnames';

import { Header } from '#component/header';
import { Footer } from '#component/footer';
// import { HeaderMarquee } from '#component/header-marquee';
// import { SignInNotification } from '#component/global-notification/sign-in';
import { CookieAgreement } from '#component/cookie-agreement';
// import { EventBanner } from '#component/banner/marketing/event';
// import { IgoBanner } from '#component/banner/marketing/igo';
import { WalletConnectBanner } from '#component/banner/marketing/wallet-connect';
import { UserStatistics } from '#component/user-statistics';

import style from './index.module.pcss';

export interface IProps {
    className?: TStyle | string
}

export const Layout = (props: IProps) => {
    const cn = useClassnames(style, props.className);

    return (
        <Fragment>
            <div className={cn('layout-sticky')}>
                {/* <HeaderMarquee /> */}
                {/* <SignInNotification /> */}
                <Header />
            </div>
            <div className={cn('layout')}>
                <UserStatistics />
                <main>
                    <Outlet />
                </main>
                <CookieAgreement />
                {/*<EventBanner />*/}
                <WalletConnectBanner />
                <Footer />
            </div>
        </Fragment>
    );
};
