import React from 'react';
import { NavLink } from 'react-router-dom';

import { useClassnames } from '#hook/use-classnames';

import airdropIcon from '../../images/airdrops.png';
import claimIcon from '../../images/claim.png';
import friendsIcon from '../../images/friends.png';
import profileIcon from '../../images/profile.png';

import styles from './index.module.pcss';

const navItems = [
    { to: '/telegram/airdrops', icon: airdropIcon, label: 'Airdrops' },
    { to: '/telegram/claim', icon: claimIcon, label: 'Claim' },
    { to: '/telegram/friends', icon: friendsIcon, label: 'Friends' },
    { to: '/telegram/profile', icon: profileIcon, label: 'Profile' }
];

export const BottomNav = React.memo(() => {
    const cn = useClassnames(styles);

    return (
        <nav className={cn('menu')}>
            {navItems.map(({ to, icon, label }) => (
                <NavLink
                    key={to}
                    to={to}
                    className={({ isActive }) => cn('menu__item', { 'menu__item--active': isActive })}
                >
                    <div className={cn('menu__icon')}>
                        <img
                            src={icon}
                            alt={label}
                        />
                    </div>
                    <div className={cn('menu__label')}>{label}</div>
                </NavLink>
            ))}
        </nav>
    );
});
