import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useDispatch } from 'react-redux';

import { useSelector } from '#core/store';
import { useWagmiFlow } from '#hook/use-wagmi-flow';
import { getDataTWA } from '#hook/get-data-twa';

import { slice as sliceGTM } from '#component/gtm/provider/slice';

interface IProps {
    children: React.ReactNode
}

const GTM_KEYS_MAP = {
    authorized: {
        common  : import.meta.env.APP_GTM_ID_AUTHORIZED,
        // TODO: добавить телеграм ключ в енвы
        telegram: import.meta.env.APP_GTM_ID_AUTHORIZED_TELEGRAM
    },
    unauthorized: {
        common  : import.meta.env.APP_GTM_ID_UNAUTHORIZED,
        // TODO: добавить телеграм ключ в енвы
        telegram: import.meta.env.APP_GTM_ID_UNAUTHORIZED_TELEGRAM
    }
};

const GTMProvider = (props: IProps) => {
    const dispatch = useDispatch();
    const { isLoading, hasNeedConnectWallet } = useWagmiFlow();
    const { inited } = useSelector((state) => state[sliceGTM.name]);
    const { isTelegram } = getDataTWA() || { isTelegram: false };

    useEffect(() => {
        if(!isLoading && !inited) {
            const authorizedStatusKey = hasNeedConnectWallet ? 'unauthorized' : 'authorized';
            const appTypeKey = isTelegram ? 'telegram' : 'common';
            const gtmId = GTM_KEYS_MAP[authorizedStatusKey][appTypeKey];

            TagManager.initialize({ gtmId });

            dispatch(sliceGTM.actions.set({
                inited: authorizedStatusKey
            }));
        }
    }, [isLoading, hasNeedConnectWallet, isTelegram, inited]);

    return props.children;
};

export default GTMProvider;
