import React from 'react';

import { TStyle, useClassnames } from '#hook/use-classnames';

import { Button, IProps as IButtonProps } from '#component/button';

import style from './index.module.pcss';

export interface ITableItem {
    id: number,
    game: string,
    amount: string,
    token: string
}

interface IProps {
    className?: string | TStyle,
    buttonProps?: IButtonProps
}

export const ClaimButton = (props: IProps) => {
    const cn = useClassnames(style, props.className);

    return (
        <Button
            className={cn('button')}
            presetSize="small"
            {...props.buttonProps}
        />
    );
};
