import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';

import { store } from '#core/store';

import { MediaQuery } from '#component/media-query';
import { WagmiProvider, ready } from '#component/wagmi/provider';
import { Routes } from '#route';
import GTMProvider from '#component/gtm/provider';

import '#locale';
// import './assets/fonts/index.css';
import './index.pcss';

const container = document.getElementById(import.meta.env.APP_HTML_ROOT_ELEMENT_ID);

if(container) {
    const root = createRoot(container);

    void ready.finally(() => {
        root.render(
            <StrictMode>
                <Provider store={store}>
                    <WagmiProvider>
                        <GTMProvider>
                            <MediaQuery>
                                <HelmetProvider>
                                    <Routes />
                                </HelmetProvider>
                            </MediaQuery>
                        </GTMProvider>
                    </WagmiProvider>
                </Provider>
            </StrictMode>
        );
    });
}

console.info('App version: %s', __VERSION__);
