import React from 'react';
import { useAccount } from 'wagmi';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { type TStyle, useClassnames } from '#hook/use-classnames';
import { useSelector } from '#core/store';
import { useWeb3Modal } from '#hook/use-web3-modal';

import { ButtonSimple } from '#component/button-simple';
import { slice as sliceMediaQuery } from '#component/media-query/slice';

import style from './index.module.pcss';

export interface IProps {
    readonly className?: TStyle | string,
    onClick?(): void
}

export const ButtonConnect = (props: IProps) => {
    const cn = useClassnames(style, props.className);
    const { t } = useTranslation();
    const { open } = useWeb3Modal();
    const { address, isConnected } = useAccount();
    const isDesktop = useSelector((store) => store[sliceMediaQuery.name].isDesktop);

    if(isConnected && address) {
        return (
            <ButtonSimple
                as={Link}
                className={cn('button-connect')}
                presetSize={isDesktop ? 'default' : 'small'}
                to="/profile"
                uppercase={true}
            >
                {isDesktop && t('components.button-connect.profile')}
                <span
                    children={`${address.slice(0, 6)}...${address.slice(-4)}`}
                />
            </ButtonSimple>
        );
    }

    return (
        <ButtonSimple
            className={cn('button-connect')}
            uppercase={true}
            presetSize={isDesktop ? 'default' : 'small'}
            children={t('components.button-connect.sign-in')}
            type="button"
            onClick={() => {
                window.SQMConvTracking?.Conversion({ 'cid': 1722575309 });

                void open();

                props.onClick?.();
            }}
        />
    );
};
