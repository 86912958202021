import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useNotification } from '#hook/use-notification';

import { EClaimStatus, STATUS_TYPES } from './colomns';

export type THandleChange = (uniqueId: string, status: EClaimStatus, customMessage?: string) => void;

export const useHandleStatusChange = () => {
    const [statusMap, setStatus] = useState<Record<string, EClaimStatus>>({});
    const { methods } = useNotification();
    const { t } = useTranslation(undefined, {
        keyPrefix: 'routes.claim.claim-status'
    });
    const handleStatusChange: THandleChange = (uniqueId, status, customMessage) => {
        // Обновляем статус в состоянии
        setStatus((prevStatus) => ({
            ...prevStatus,
            [uniqueId]: status
        }));

        // Формируем сообщение для отображения
        const message = customMessage ? `${t(status)} ${customMessage}` : t(status);
        const statusType = STATUS_TYPES[status];

        // Отображаем уведомление в зависимости от типа статуса
        if(statusType === 'error') {
            methods.error(message);
        } else {
            methods.success(message);
        }
    };

    return { statusMap, handleStatusChange };
};
