import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAccount } from 'wagmi';

import { useAmplitude } from '#adapter/amplitude/use-amplitude';
import api from '#adapter/api/main';
import { useSelector } from '#core/store';

import { slice as telegramSlice } from '#route/telegram/slice';

export const useSessionStarted = () => {
    const location = useLocation();
    const { connector, isConnected } = useAccount();
    const { data: apiData } = api.endpoints.apiV1UsersWhoamiGet.useQuery();
    const { track } = useAmplitude();

    // Получаем данные из telegramSlice
    const telegramData = useSelector((store) => store[telegramSlice.name]);

    useEffect(() => {
        if(isConnected && connector) {
            track('tgapp_session_started', {
                is_referral : Boolean(apiData?.registered_by_affiliate),
                ref_id      : telegramData?.refKey, // Используем refKey из хранилища
                user_id     : telegramData?.user?.id || 'unknown', // Используем user id из хранилища
                username    : telegramData?.user?.username || '@unknown', // Используем username из хранилища
                user_ref_key: apiData?.affiliate_links?.[0]?.link_key || '',
                lang_code   : telegramData.user?.language_code || 'en',
                start_page  : location.pathname
            });
        }
    }, [location]);
};
